<template>
  <b-form @submit.prevent="signIn">
    <b-alert variant="danger" :show="!(!error)">{{ error }}</b-alert>
    <b-form-group>
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-input-group-prepend is-text>
          <b-icon icon="person-fill"></b-icon>
        </b-input-group-prepend>
        <b-form-input id="inline-form-input-username" v-model="login.username" required placeholder="ID" class="shadow-sm login-input"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
        <b-input-group-prepend is-text>
          <b-icon icon="key-fill"></b-icon>
        </b-input-group-prepend>
        <b-form-input type="password" id="inline-form-input-password" v-model="login.password" placeholder="password" required class="shadow-sm login-input"></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <b-btn class="mt-2 rounded login_btn" block v-if="loading">
        <b-spinner />
      </b-btn>
      <b-btn type="submit" value="SignIn" class="mt-2 rounded login_btn" block v-else>
        アカウントにサインイン
      </b-btn>
    </b-form-group>
  </b-form>
</template>

<script>
import { Auth, Logger } from 'aws-amplify'

const logger = new Logger('auth_signIn')

export default {
  name: 'auth_signIn',
  data () {
    return {
      loading: false,
      login: {
        username: '',
        password: '',
        isErrorEmail: false,
        isErrorPassword: false
      },
      error: null
    }
  },
  methods: {
    validateUsername (username) {
      return username
    },
    validatePassword: function (password) {
      return password
    },
    validate: function () {
      this.error = null
      this.login.isErrorUsername = !this.validateUsername(this.login.username)
      this.login.isErrorPassword = !this.validatePassword(this.login.password)
      if (this.login.isErrorUsername) {
        this.error = 'IDを正しく入力してください。(F)'
      } else if (this.login.isErrorPassword) {
        this.error = 'パスワードを入力してください。(F)'
      }
      return !this.error
    },
    translateLocalMessage (error) {
      return {
        'NotAuthorizedException': 'IDまたはパスワードが間違っています(S)',
        'UserNotFoundException': '登録されていないIDです(S)',
        'UserNotConfirmedException': 'アカウントを確認してください(S)'
      }[error.code] || error.message
    },
    async signIn () {
      if (this.loading || !this.validate()) return
      this.loading = true
      this.error = null
      try {
        let user = await Auth.signIn(this.login.username, this.login.password)
        logger.debug('sign in success', user)
        if (user.challengeName) {
          await Auth.completeNewPassword(user, this.login.password)
        } else {
          this.$store.commit('setUser', user)
        }
        this.user = user
        this.checkUser()
      } catch (error) {
        this.loading = false
        this.login.password = null
        this.setError(error)
      }
    },
    checkUser () {
      const user = this.user
      if (!user) { return }

      Auth.verifiedContact(user)
        .then(data => {
          logger.debug('verify result', data)
          this.$store.commit('setUserVerification', data)
          this.$router.push(this.$route.query.redirect || '/home/dashboard')
        })
    },
    confirm () {
      Auth.confirmSignIn(this.user, this.code)
        .then(() => {
          this.$router.push('/home/dashboard')
        })
        .catch(err => this.setError(err))
    },
    forgot: function () {
      this.$router.push('/auth/forgotPassword')
    },
    signUp: function () {
      this.$router.push('/auth/signUp')
    },
    setError: function (err) {
      this.error = this.translateLocalMessage(err) || err
    }
  }
}
</script>

<style scoped>
.input-group-prepend {
  background-color: #FAB206;
}
.input-group-text {
  background-color: #FAB206;
}
.input-group-prepend span {
  width: 50px;
  background-color: #FAB206;
  color: black;
  border:0 !important;
}

input:focus {
  outline: 0 0 0 0  !important;
  box-shadow: 0 0 0 0 !important;
}
.login-input {
  color: #000;
  font-size: 18px;
  padding: 10px 12px;
  height: 48px;
}

.login_btn{
  background: linear-gradient(#e9798a,#c9494a);
  color: #fff;
  font-weight: 700;
  height: 48px;
}

.login_btn:hover{
  color: white;
  background-color: white;
}
</style>
